import * as React from 'react';
import Layout from '../../components/Layout';

const InternalGuidelines = props=>{
    return (<Layout>

    <div className="page-header image-header" id="overview" data-navindex="1" style={{backgroundColor: 'rgb(235, 10, 30)',height: '846px'}}>
        <div className="content-flex" style={{color:'#fff',top:'',textAlign:'left'}}>
            <h1 className="copy" style={{color:'#fff',top:'',textAlign:'left'}}>
                Vestibulum lorem tellus, laoreet sed tempor non, dinissim vitae. 
                Pellentesque sagittis leo id fermentum accumsan.
            </h1>
        </div>
    </div>
    <section className='component Section' id='cultural'>
        <div className='section-header'>
            <div className='container'>
                <h2 className='ui header'>Cultural Pillars</h2>
            </div>
        </div>
        <div className='blog-content'>
            Only use the staging shape versions for co-branding logo lockups. From the four configurations, 
            select the version that best matches the shape of the co-branding logo and the surrounding lauout. 
            The Toyota logo should carry the same visual weight as the co-branding logo, and unless otherwise 
            specified, the Toyota logo should be first in the lockup when it can be accomodated. Use 
            full-color whenever possible, but when appropriate, refer to the 1-color guidelines.
        </div>
    </section>

    <section className='component Section' id='store-logos'>
        <div className='section-header'>
            <div className='container'>
                <h2 className='ui header'>Store logos</h2>
            </div>
        </div>
        <div className='blog-content'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet efficitur rutrum. 
            In vitae augue odio. Curabitur et tortor pharetra, eleifend dui vel, dictum ligula. 
            Curabitur sodales at lorem eget malesuada. Vivamus finibus mollis commodo. Cras posuere 
            ipsum neque, nec gravida tortor bibendum at. Curabitur mattis sollicitudin sollicitudin.
        </div>

        {/* TODO: Two-tab image */}
    </section>

    <section className='component Section' id='office-signage'>
        <div className='section-header'>
            <div className='container'>
                <h2 className='ui header'>Office signage</h2>
            </div>
        </div>
        <div className='blog-content'>
            {/* TODO: images */}
        </div>

        {/* TODO: Two-tab image */}
    </section>

    <section className='component Section' id='store-signage'>
        <div className='section-header'>
            <div className='container'>
                <h2 className='ui header'>Store signage</h2>
            </div>
        </div>
        <div className='blog-content'>
            {/* TODO: images */}
        </div>

        {/* TODO: Two-tab image */}
    </section>

    <section className='component Section' id='email-signature'>
        <div className='section-header'>
            <div className='container'>
                <h2 className='ui header'>Email signature</h2>
            </div>
        </div>
        <div className='blog-content'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet efficitur rutrum. 
            In vitae augue odio. Curabitur et tortor pharetra, eleifend dui vel, dictum ligula. 
            Curabitur sodales at lorem eget malesuada. Vivamus finibus mollis commodo. Cras posuere
        </div>

        {/* TODO: Image carousel */}
    </section>

    <section className='component Section' id='presentation-template'>
        <div className='section-header'>
            <div className='container'>
                <h2 className='ui header'>Presentation template</h2>
            </div>
        </div>
        <div className='blog-content'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet efficitur rutrum. 
            In vitae augue odio. Curabitur et tortor pharetra, eleifend dui vel, dictum ligula. 
            Curabitur sodales at lorem eget malesuada. Vivamus finibus mollis commodo. Cras posuere
        </div>

        {/* TODO: Templates for download */}
    </section>

    <section className='component Section' id='lanyards'>
        <div className='section-header'>
            <div className='container'>
                <h2 className='ui header'>Lanyards</h2>
            </div>
        </div>
        <div className='blog-content'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet efficitur rutrum. 
            In vitae augue odio. Curabitur et tortor pharetra, eleifend dui vel, dictum ligula. 
            Curabitur sodales at lorem eget malesuada. Vivamus finibus mollis commodo. Cras posuere
        </div>

        {/* TODO: Some images */}
    </section>

    <section className='component Section' id='toyotalife'>
        <div className='section-header'>
            <div className='container'>
                <h2 className='ui header'>#Toyotalife Template</h2>
            </div>
        </div>
        <div className='blog-content'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet efficitur rutrum. 
            In vitae augue odio. Curabitur et tortor pharetra, eleifend dui vel, dictum ligula. 
            Curabitur sodales at lorem eget malesuada. Vivamus finibus mollis commodo. Cras posuere
        </div>

        {/* TODO: Templates for download */}
    </section>

        {/* TODO: bottom navidation and footer. Confirm Asset Downloads in the navigation. */}

    
        </Layout>);
}

export default InternalGuidelines;
